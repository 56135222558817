<template>
  <div class="column">
      <h1>Help</h1>
      <h2>Gyorsbillentyűk</h2>
      <dl>
          <dt class="small-1">b</dt><dd class="small-11">Új bizonylat</dd>
          <dt class="small-1">h</dt><dd class="small-11">Help</dd>
      </dl>
  </div>
</template>

<script>
export default {
    name: 'help'
}
</script>

<style scoped>
dl {
    display: flex;
    flex-wrap: wrap;
}
</style>